import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SKIP_GLOBAL_ERROR } from '../../../../core/interceptor/http.interceptor';
import { ResponseDto } from '../../../../shared/models/response-dto.model';
import { BundleOrganicSummary } from '../models/bundle-organic-summary.model';
import { BundleSummary, BundleSummaryContent } from '../models/bundle-summary.model';

@Injectable({
  providedIn: 'root',
})
export class SummaryService {
  private readonly http = inject(HttpClient);

  getOrganicSummaryByBundleId(bundleId: string): Observable<BundleOrganicSummary> {
    return this.http
      .get<ResponseDto<BundleOrganicSummary>>(`/summary/${bundleId}/organic-summary`)
      .pipe(map((response) => response.data));
  }

  getSummaryByBundleId(bundleId: string): Observable<BundleSummary> {
    return this.http
      .get<ResponseDto<BundleSummary>>(`/summary/${bundleId}`, {
        context: new HttpContext().set(SKIP_GLOBAL_ERROR, true),
      })
      .pipe(map((response) => response.data));
  }

  generateSummaryById(bundleId: string): Observable<void> {
    return this.http.post<void>(`/summary/${bundleId}/generate`, {});
  }

  uploadSummaryCsv(bundleId: string, file: File): Observable<void> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', file.name);

    return this.http.put<void>(`/summary/${bundleId}`, formData);
  }

  getLinkByFieldId(bundleId: string, fieldId: string): Observable<BundleSummaryContent> {
    return this.http
      .get<ResponseDto<BundleSummaryContent>>(`/summary/${bundleId}/link/${fieldId}`)
      .pipe(map((response) => response.data));
  }

  deleteSummaryById(bundleId: string): Observable<void> {
    return this.http.delete<void>(`/summary/${bundleId}`);
  }

  validateFields(bundleId: string, fields: string[]): Observable<void> {
    return this.http.post<void>(`/summary/${bundleId}/validate`, { fields });
  }

  sendToCrm(bundleId: string): Observable<void> {
    return this.http.get<void>(`/summary/sent-to-crm/${bundleId}`);
  }

  setUserAnswer(bundleId: string, fieldId: string, manualAnswer: string): Observable<void> {
    return this.http.put<void>(`/summary/${bundleId}/user-answer`, { fieldId, manualAnswer });
  }
}
